@import './variable.scss';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1em;
}

.MuiTextField-root {
  background-color: white;
}

.MuiToolbar-root {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

button.pinkRed {
  &:hover {
    background-color: $pinkRed;
    opacity: 0.8;
    box-shadow: none;
  }
  background-color: $pinkRed;
  box-shadow: none;
  font-weight: 600;
  border-radius: 0.8em;
}

button.white {
  &:hover {
    background-color: white;
    box-shadow: none;
  }
  color: black;
  background-color: white;
  box-shadow: none;
  border-radius: 0.8em;
}

.banner {
  background-image: linear-gradient(to right, $pinkRed, $linkYellow);
}